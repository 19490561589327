import { RematchDispatch, RematchRootState, init } from '@rematch/core';
import selectPlugin from '@rematch/select';

import { RootModel, models } from './models';
import { ldPlugin } from './plugins/ld-redux';

export const store = init<RootModel>({
  models,
  redux: {
    devtoolOptions: {
      disabled: process.env.NODE_ENV === 'production',
    },
  },
  plugins: [ldPlugin(process.env.REACT_APP_LAUNCH_DARKLY_ID!), selectPlugin()],
});

export const { dispatch, select } = store;

export type Store = typeof store;
export type Dispatch = RematchDispatch<RootModel>;
export type RootState = RematchRootState<RootModel>;
