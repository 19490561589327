import { PosVendor } from '@rbilabs/intl-common';
import { createModel } from '@rematch/core';

import { AvailabilityModes, GetRestaurantsQuery, RestaurantFragmentFragment } from 'src/graphql';
import type { RootState } from 'src/rematch/store';

import type { RootModel } from '.';

export type Segment = 'restaurants' | 'groups';

export interface RestaurantsState {
  data: Record<string, RestaurantFragmentFragment>;
  selected: Array<RestaurantFragmentFragment['id']>;
  sourceCopyRestaurant: RestaurantFragmentFragment['id'];
  targetCopyRestaurants: Array<RestaurantFragmentFragment['id']>;
  filters: {
    searchTerm: string;
    posVendor: string | null;
    availability: Array<AvailabilityModes>;
    segment: Segment;
  };
}

/**
 * Restaurants redux store
 */
export const restaurants = createModel<RootModel>()({
  state: {
    data: {},
    selected: [],
    sourceCopyRestaurant: '',
    targetCopyRestaurants: [],
    filters: {
      searchTerm: '',
      posVendor: null,
      availability: [],
      segment: 'restaurants',
    },
  } as RestaurantsState,

  reducers: {
    /**
     * Stores a hash of all the restaurants
     */
    setRestaurants(state, payload?: GetRestaurantsQuery) {
      const restaurants = payload?.userRestaurants?.allRestaurants;

      if (!restaurants) {
        return state;
      }
      return {
        ...state,
        data: restaurants.reduce<RestaurantsState['data']>(
          (hash, restaurant) => ({
            ...hash,
            [restaurant.id]: restaurant,
          }),
          {}
        ),
      };
    },

    /**
     * Toggle selection of a restaurants
     */
    setSelectedRestaurants: (state, payload: RestaurantFragmentFragment['id']) => {
      if (state.selected.includes(payload)) {
        return {
          ...state,
          selected: state.selected.filter(id => id !== payload),
        };
      }
      return {
        ...state,
        selected: [...state.selected, payload],
      };
    },

    setSourceCopyRestaurant: (state, payload: RestaurantFragmentFragment['id']) => {
      if (state.sourceCopyRestaurant === payload) {
        return {
          ...state,
          sourceCopyRestaurant: '',
        };
      }
      return {
        ...state,
        sourceCopyRestaurant: payload,
      };
    },

    clearSourceCopyRestaurant: state => {
      return {
        ...state,
        sourceCopyRestaurant: '',
      };
    },

    /**
     * These restaurants will receive the plus from the Copy functionality
     */
    setTargetCopyRestaurants: (
      state,
      payload: RestaurantFragmentFragment['id'] | Array<RestaurantFragmentFragment['id']>
    ) => {
      if (Array.isArray(payload)) {
        const { targetCopyRestaurants } = state;
        const targetRestaurants = payload.every(restaurantId =>
          targetCopyRestaurants.includes(restaurantId)
        )
          ? []
          : payload;
        return {
          ...state,
          targetCopyRestaurants: targetRestaurants,
        };
      }
      if (state.selected.includes(payload)) {
        return state;
      }

      if (state.targetCopyRestaurants.includes(payload)) {
        return {
          ...state,
          targetCopyRestaurants: state.targetCopyRestaurants.filter(id => id !== payload),
        };
      }

      return {
        ...state,
        targetCopyRestaurants: [...state.targetCopyRestaurants, payload],
      };
    },

    /**
     * Toggle selection of the filtered restaurants
     */
    setSelectedRestaurantsAll: (
      state,
      payload: { ids: Array<RestaurantFragmentFragment['id']>; editableVendors: Array<PosVendor> }
    ) => {
      const { ids, editableVendors } = payload;
      const editableRestaurantIds = ids.filter(id =>
        editableVendors.includes(state.data[id].pos?.vendor as PosVendor)
      );

      if (editableRestaurantIds.every(id => state.selected.includes(id))) {
        return { ...state, selected: [] };
      }
      return { ...state, selected: [...editableRestaurantIds] };
    },

    /**
     * Clears selected restaurants
     */
    clearSelectedRestaurants: state => {
      return { ...state, selected: [] };
    },

    clearTargetCopyRestaurants: state => {
      return { ...state, targetCopyRestaurants: [] };
    },

    /**
     * Sets the restaurant filter search term
     */
    setSearchTerm(state, payload: string) {
      return {
        ...state,
        filters: {
          ...state.filters,
          searchTerm: payload,
        },
      };
    },

    /**
     * Toggles the restaurant filter POS Vendor
     */
    setPosVendor: (state, payload: string) => {
      if (payload === state.filters.posVendor) {
        return {
          ...state,
          filters: {
            ...state.filters,
            posVendor: null,
          },
        };
      }

      return {
        ...state,
        filters: {
          ...state.filters,
          posVendor: payload,
        },
      };
    },

    /**
     * Toggles the restaurant availability filter
     */
    setAvailability: (state, payload: AvailabilityModes | null) => {
      if (payload && state.filters.availability.includes(payload)) {
        return {
          ...state,
          filters: {
            ...state.filters,
            availability: state.filters.availability.filter(element => element !== payload),
          },
        };
      }

      if (payload && !state.filters.availability.includes(payload)) {
        return {
          ...state,
          filters: {
            ...state.filters,
            availability: [...state.filters.availability, payload],
          },
        };
      }
    },

    /**
     * Updates the selected segment
     */
    setSegment: (state, payload: Segment) => {
      return { ...state, filters: { ...state.filters, segment: payload } };
    },
  },

  effects: dispatch => ({
    /**
     * Triggers an update of the editor model
     */
    setSelectedRestaurants(_payload: RestaurantFragmentFragment['id']) {
      this.updateSelection();
    },

    /**
     * Triggers an update of the editor model
     */
    setSelectedRestaurantsAll() {
      this.updateSelection();
    },

    /**
     * Clears the restaurants of the editor and the restaurantGroups models
     */
    clearSelectedRestaurants() {
      dispatch.editor.setRestaurants([]);
      dispatch.restaurantGroups.clearSelectedGroups();
    },

    /**
     * Clears the restaurants of the editor and the restaurantGroups models
     */
    clearCopyMode() {
      dispatch.restaurants.clearTargetCopyRestaurants();
      dispatch.editor.setIsCopyMode(false);
    },

    /**
     * Updates the editor and the restaurantGroups models with the data of the selected restaurants
     */
    updateSelection(_, rootState) {
      const selectedRestaurants = rootState.restaurants.selected.map(
        id => rootState.restaurants.data[id]
      );
      dispatch.editor.setRestaurants(selectedRestaurants);
      const editableVendors = rootState.editableVendors;
      dispatch.restaurantGroups.updateSelectedGroups({
        selected: rootState.restaurants.selected,
        editableVendors,
      });
    },
  }),

  selectors: (slice, createSelector) => ({
    /**
     * Memoized list of all the restaurants
     */
    allRestaurants() {
      return createSelector([slice(state => state.data)], restaurants => {
        return Object.values(restaurants);
      });
    },

    /**
     * Memoized list of filtered the restaurants
     */
    filteredRestaurants() {
      return createSelector([this.allRestaurants, this.searchTerm], (ar, st) => {
        const allRestaurants = ar as unknown as RestaurantFragmentFragment[];
        const searchTerm = st as unknown as string;
        if (!allRestaurants) {
          return [];
        }

        if (!searchTerm) {
          return allRestaurants;
        }

        let result = allRestaurants;

        if (searchTerm) {
          result = result.filter(restaurant => {
            return [restaurant.id, restaurant.name.toLocaleLowerCase()].some(r =>
              r.includes(searchTerm.toLowerCase())
            );
          });
        }

        return result;
      });
    },

    /**
     * Memoized list of selected restaurants
     */
    targetCopyRestaurants() {
      return slice(state => state.targetCopyRestaurants);
    },

    /**
     * Memoized value of source copy restaurant
     */
    sourceCopyRestaurant() {
      return slice(state => state.sourceCopyRestaurant);
    },

    /**
     * Memoized list of selected restaurants
     */
    selectedRestaurants() {
      return slice(state => state.selected);
    },

    /**
     * Memoized value of the restaurant search filter
     */
    searchTerm() {
      return slice(state => state.filters.searchTerm);
    },

    /**
     * Memoized value of the restaurant POS Vendor filter
     */
    posVendor() {
      return slice(state => state.filters.posVendor);
    },

    /**
     * Memoized value of the restaurant availability filter
     */
    availability() {
      return slice(state => state.filters.availability);
    },

    /**
     * Memoized value of the restaurant segment filter
     */
    segment() {
      return slice(state => state.filters.segment);
    },

    areAllRestaurantsSelected() {
      return createSelector(
        [
          this.sourceCopyRestaurant,
          this.selectedRestaurants,
          this.filteredRestaurants,
          (state: RootState) => state.editableVendors,
          (state: RootState) => state.editor.isCopyMode,
          this.targetCopyRestaurants,
        ],
        (scpr, sr, fr, ev, cm, tc) => {
          const selectedRestaurants = sr as unknown as RestaurantsState['selected'];
          const filteredRestaurants = fr as unknown as Array<RestaurantFragmentFragment>;
          const targetCopyRestaurants = tc as unknown as RestaurantsState['targetCopyRestaurants'];
          const sourceCopyRestaurant = scpr as unknown as RestaurantsState['sourceCopyRestaurant'];
          const editableVendors = ev;
          const isCopyMode = cm;

          let editableRestaurants = filteredRestaurants.filter(r =>
            editableVendors.includes(r.pos?.vendor as PosVendor)
          );
          let allRestaurantsAreSelected =
            selectedRestaurants.length > 0 &&
            editableRestaurants.every(({ id }) => selectedRestaurants.includes(id));
          if (isCopyMode) {
            // In Copy mode, editable restaurants cannot include the selectedRestaurant
            editableRestaurants = filteredRestaurants.filter(
              r =>
                r.id !== sourceCopyRestaurant &&
                editableVendors.includes(r.pos?.vendor as PosVendor)
            );
            allRestaurantsAreSelected =
              selectedRestaurants.length > 0 &&
              editableRestaurants.every(({ id }) => targetCopyRestaurants.includes(id));
          }

          return allRestaurantsAreSelected;
        }
      );
    },
  }),
});
