import { IsoCountryCode2Char, PosVendor } from '@rbilabs/intl-common';
import { createModel } from '@rematch/core';

import type { RootModel } from '.';

export type EnableMenuSyncFlag = {
  enabled: boolean;
  regions: IsoCountryCode2Char[];
  posVendors: PosVendor[];
};

export type EnableRiderAppPolicySection = {
  enabled: boolean;
  regions: IsoCountryCode2Char[];
};

export interface ILaunchDarkly {
  isLDReady: boolean;
  enableDiagnostics: boolean;
  enablePricingAdjustments: boolean;
  enableUploadPricesSection: boolean;
  enableUploadItemDataSection: boolean;
  digitalManagementPortalV2: boolean;
  evaluatePaymentMethodBrand: boolean;
  enableAnalyticsPage: boolean;
  enableErrorsPage: boolean;
  enableDeliveryPage: boolean;
  enableGuidesPage: boolean;
  enableSections: boolean;
  enablePartnerPosVendor: boolean;
  enableMenuSync: EnableMenuSyncFlag;
  enableLoyaltyDownload: boolean;
  enableUserManagementPage: boolean;
  enableHistoryPage: boolean;
  enableCashLimitation: boolean;
  enableEditor: boolean;
  maxStoresPerBatch: number;
  enableLateNight: boolean;
  enableAutoFireDmpFeature: boolean;
  enableComplaintsCompensationsDmpFeature: boolean;
  enableMultiMarket: boolean;
  maxConcurrentValidationRequests: number;
  enableRiderAppPrivacyPolicySection: EnableRiderAppPolicySection;
  enableNewCsvUploadFormat: boolean;
  enablePaymentMethodsControl: boolean;
}

export const launchDarkly = createModel<RootModel>()({
  state: {} as Partial<ILaunchDarkly>,
  selectors: slice => ({
    isLDReady() {
      return slice(state => state.isLDReady);
    },
    enableDiagnostics() {
      return slice(state => state.enableDiagnostics);
    },
    enablePricingAdjustments() {
      return slice(state => state.enablePricingAdjustments);
    },
    enableUploadPricesSection() {
      return slice(state => state.enableUploadPricesSection);
    },
    enableUploadItemDataSection() {
      return slice(state => state.enableUploadItemDataSection);
    },
    digitalManagementPortalV2() {
      return slice(state => state.digitalManagementPortalV2);
    },
    evaluatePaymentMethodBrand() {
      return slice(state => state.evaluatePaymentMethodBrand);
    },
    enableDeliveryPage() {
      return slice(state => state.enableDeliveryPage);
    },
    enableAnalyticsPage() {
      return slice(state => state.enableAnalyticsPage);
    },
    enableErrorsPage() {
      return slice(state => state.enableErrorsPage);
    },
    enableGuidesPage() {
      return slice(state => state.enableGuidesPage);
    },
    enableSections() {
      return slice(state => state.enableSections);
    },
    enablePartnerPosVendor() {
      return slice(state => state.enablePartnerPosVendor);
    },
    enableMenuSync() {
      return slice(state => state.enableMenuSync);
    },
    enableLoyaltyDownload() {
      return slice(state => state.enableLoyaltyDownload);
    },
    enableUserManagementPage() {
      return slice(state => state.enableUserManagementPage);
    },
    enableHistoryPage() {
      return slice(state => state.enableHistoryPage);
    },
    enableCashLimitation() {
      return slice(state => state.enableCashLimitation);
    },
    enableEditor() {
      return slice(state => state.enableEditor);
    },
    maxStoresPerBatch() {
      return slice(state => state.maxStoresPerBatch);
    },
    enableLateNight() {
      return slice(state => state.enableLateNight);
    },
    enableAutoFireDmpFeature() {
      return slice(state => state.enableAutoFireDmpFeature);
    },
    enableComplaintsCompensationsDmpFeature() {
      return slice(state => state.enableComplaintsCompensationsDmpFeature);
    },
    enableMultiMarket() {
      return slice(state => state.enableMultiMarket);
    },
    maxConcurrentValidationRequests() {
      return slice(state => state.maxConcurrentValidationRequests);
    },
    enableRiderAppPrivacyPolicySection() {
      return slice(state => state.enableRiderAppPrivacyPolicySection);
    },
    enableNewCsvUploadFormat() {
      return slice(state => state.enableNewCsvUploadFormat);
    },
    enablePaymentMethodsControl() {
      return slice(state => state.enablePaymentMethodsControl);
    },
  }),
});
